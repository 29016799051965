<template>
  <ion-grid fixed>
    <ion-row class="ion-align-items-center">
      <ion-text style="font-weight:bold; font-size:137%;">Agua</ion-text>
      <ion-button
        fill="clear"
        size="small"
        @click="modalEditAguaHandler"
        v-if="isEditable"
      >
        <ion-icon :md="icons.edit" :ios="icons.edit" color="dark"></ion-icon>
      </ion-button>
    </ion-row>
    <ion-row>
      <ion-text>{{ "Napa:" }}</ion-text>
      <ion-text style="margin-left: 2px; font-weight:bold;" color="primary">
        {{ napa }}
      </ion-text>
    </ion-row>
    <ion-row>
      <ionText>{{ "Profundidad Napa:" }}</ionText>
      <ion-text
        style="margin-left: 2px; font-weight:bold;"
        color="primary"
        v-if="profundidadNapa"
      >
        {{ profundidadNapa + " m" }}
      </ion-text>
    </ion-row>
    <ion-row>
      <ionText>{{ "Estado Perfil a la Siembra:" }}</ionText>
      <ion-text style="margin-left: 2px; font-weight:bold;" color="primary">
        {{ estadoPerfilEnSiembra }}
      </ion-text>
    </ion-row>
    <ion-row class="ion-align-items-center ion-margin-top">
      <ion-text style="font-weight:bold; font-size:137%;">Suelo</ion-text>
      <ion-button
        fill="clear"
        size="small"
        @click="modalEditSueloHandler"
        v-if="isEditable"
      >
        <ion-icon :md="icons.edit" :ios="icons.edit" color="dark"></ion-icon>
      </ion-button>
    </ion-row>
    <ion-row>
      <ion-text>{{ "Fecha de Análisis de Suelo:" }}</ion-text>
      <ion-text
        style="margin-left: 2px; font-weight:bold;"
        color="primary"
        v-if="fechaAnalisis"
      >
        {{ formatDate }}
      </ion-text>
    </ion-row>
    <ion-row>
      <ion-text>{{ "Fósforo:" }}</ion-text>
      <ion-text
        style="margin-left: 2px; font-weight:bold;"
        color="primary"
        v-if="fosforo"
      >
        {{ fosforo + " ppm" }}
      </ion-text>
    </ion-row>
    <ion-row>
      <ion-text>{{ "N-NO3 (0-20cm):" }}</ion-text>
      <ion-text
        style="margin-left: 2px; font-weight:bold;"
        color="primary"
        v-if="nn03_0_20cm"
      >
        {{ nn03_0_20cm + " kg/ha" }}
      </ion-text>
    </ion-row>
    <ion-row>
      <ion-text>{{ "N-NO3 (20-40cm):" }}</ion-text>
      <ion-text
        style="margin-left: 2px; font-weight:bold;"
        color="primary"
        v-if="nn03_20_40cm"
      >
        {{ nn03_20_40cm + " kg/ha" }}
      </ion-text>
    </ion-row>
    <ion-row>
      <ion-text>{{ "N-NO3 (40-60cm):" }}</ion-text>
      <ion-text
        style="margin-left: 2px; font-weight:bold;"
        color="primary"
        v-if="nn03_40_60cm"
      >
        {{ nn03_40_60cm + " kg/ha" }}
      </ion-text>
    </ion-row>
    <ion-row>
      <ion-text>{{ "Materia Orgánica:" }}</ion-text>
      <ion-text
        style="margin-left: 2px; font-weight:bold;"
        color="primary"
        v-if="fosforo"
      >
        {{ materiaOrganica + "%" }}
      </ion-text>
    </ion-row>
    <ion-row>
      <ion-text>{{ "Agua Útil a la Siembra:" }}</ion-text>
      <ion-text
        style="margin-left: 2px; font-weight:bold;"
        color="primary"
        v-if="aguaUtilSiembra"
      >
        {{ aguaUtilSiembra + " mm" }}
      </ion-text>
    </ion-row>
  </ion-grid>
  <ion-modal
    :is-open="modalEditAgua"
    @didDismiss="modalEditAgua = false"
    :backdropDismiss="false"
  >
    <ion-card>
      <ion-card-title
        class="ion-margin-top"
        style="margin-left:16px; font-weight: bold;"
      >
        Agua
      </ion-card-title>
      <ion-card-content>
        <ion-item class="ion-margin-top">
          <ion-label position="floating">
            Napa
          </ion-label>
          <ion-select
            :value="modalAgua.napa"
            @ionChange="modalAgua.napa = $event.target.value"
          >
            <ion-select-option v-for="n in napas" :value="n" :key="n">
              {{ n }}
            </ion-select-option>
          </ion-select>
        </ion-item>
        <ion-item>
          <ion-label position="floating">Profundidad de Napa</ion-label>
          <ion-input
            v-model="modalAgua.profundidadNapa"
            autocapitalize="off"
            type="text"
            inputmode="text"
            autocomplete="off"
            placeholder="metros"
          ></ion-input>
        </ion-item>
        <ion-item>
          <ion-label position="floating">
            Estado de Perfil a la Siembra
          </ion-label>
          <ion-select
            :value="modalAgua.estadoPerfilEnSiembra"
            @ionChange="modalAgua.estadoPerfilEnSiembra = $event.target.value"
          >
            <ion-select-option v-for="e in estadosPerfil" :value="e" :key="e">
              {{ e }}
            </ion-select-option>
          </ion-select>
        </ion-item>
        <ion-row class="ion-float-right">
          <ion-col>
            <ion-button
              fill="outline"
              class="ion-margin-top"
              color="medium"
              @click="cancel"
            >
              Cancelar
            </ion-button>
          </ion-col>
          <ion-col>
            <ion-button
              class="ion-float-right ion-margin-top"
              @click="submitAgua"
            >
              Aceptar
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-card-content>
    </ion-card>
  </ion-modal>
  <ion-modal
    :is-open="modalEditSuelo"
    @didDismiss="modalEditSuelo = false"
    :backdropDismiss="false"
  >
    <ion-content :scroll-events="true">
      <ion-card>
        <ion-card-title
          class="ion-margin-top"
          style="margin-left:16px; font-weight: bold;"
        >
          Suelo
        </ion-card-title>
        <ion-card-content>
          <ion-item>
            <ion-label position="floating">
              Fecha de Análisis de Suelo
            </ion-label>
            <ion-datetime
              v-model="modalSuelo.fechaAnalisis"
              :value="modalSuelo.fechaAnalisis"
              display-timezone="Etc/GMT+3"
              display-format="DD/MM/YY"
              picker-format="DD/MM/YY"
              cancelText="Cancelar"
              doneText="Aceptar"
            ></ion-datetime>
          </ion-item>
          <ion-item>
            <ion-label position="floating">Fósforo</ion-label>
            <ion-input
              v-model="modalSuelo.fosforo"
              autocapitalize="off"
              type="text"
              inputmode="text"
              autocomplete="off"
              placeholder="ppm"
            ></ion-input>
          </ion-item>
          <ion-item>
            <ion-label position="floating">N-NO3 (0-20cm)</ion-label>
            <ion-input
              v-model="modalSuelo.nn03_0_20cm"
              autocapitalize="off"
              type="text"
              inputmode="text"
              autocomplete="off"
              placeholder="kg/ha"
            ></ion-input>
          </ion-item>
          <ion-item>
            <ion-label position="floating">N-NO3 (20-40cm)</ion-label>
            <ion-input
              v-model="modalSuelo.nn03_20_40cm"
              autocapitalize="off"
              type="text"
              inputmode="text"
              autocomplete="off"
              placeholder="kg/ha"
            ></ion-input>
          </ion-item>
          <ion-item>
            <ion-label position="floating">N-NO3 (40-60cm)</ion-label>
            <ion-input
              v-model="modalSuelo.nn03_40_60cm"
              autocapitalize="off"
              type="text"
              inputmode="text"
              autocomplete="off"
              placeholder="kg/ha"
            ></ion-input>
          </ion-item>
          <ion-item>
            <ion-label position="floating">Materia Orgánica</ion-label>
            <ion-input
              v-model="modalSuelo.materiaOrganica"
              autocapitalize="off"
              type="text"
              inputmode="text"
              autocomplete="off"
              placeholder="Porcentaje (%)"
            ></ion-input>
          </ion-item>
          <ion-item>
            <ion-label position="floating">Agua Útil a la Siembra</ion-label>
            <ion-input
              v-model="modalSuelo.aguaUtilSiembra"
              autocapitalize="off"
              type="text"
              inputmode="text"
              autocomplete="off"
              placeholder="mm"
            ></ion-input>
          </ion-item>
          <ion-row class="ion-float-right">
            <ion-col>
              <ion-button
                fill="outline"
                class="ion-margin-top"
                color="medium"
                @click="cancel"
              >
                Cancelar
              </ion-button>
            </ion-col>
            <ion-col>
              <ion-button
                class="ion-float-right ion-margin-top"
                @click="submitSuelo"
              >
                Aceptar
              </ion-button>
            </ion-col>
          </ion-row>
        </ion-card-content>
      </ion-card>
    </ion-content>
  </ion-modal>
</template>

<script>
import {
  //IonPage,
  IonCard,
  IonCardContent,
  IonCardTitle,
  IonContent,
  IonDatetime,
  IonText,
  IonRow,
  IonCol,
  IonItem,
  IonLabel,
  IonSelect,
  IonSelectOption,
  IonGrid,
  IonInput,
  IonIcon,
  alertController,
  IonButton,
  IonModal
} from "@ionic/vue";
import { Agua, Suelo } from "@/api";
import { mapState, mapActions } from "vuex";
import { pencilOutline } from "ionicons/icons";
//import isEmail from "validator/lib/isEmail";

export default {
  components: {
    //IonPage,
    IonCard,
    IonCardContent,
    IonCardTitle,
    IonText,
    IonDatetime,
    IonRow,
    IonCol,
    IonItem,
    IonLabel,
    IonContent,
    IonSelect,
    IonSelectOption,
    IonInput,
    IonGrid,
    IonIcon,
    IonButton,
    IonModal
    //VField: V.Field,
    //VErrorMessage: V.ErrorMessage
  },

  props: {
    success: { type: Boolean, default: false },
    error: { type: String, default: "" },
    campaniaCultivo: { type: Object, default: () => ({}) }
  },

  data: () => ({
    agua: {},
    modalEditAgua: false,
    suelo: {},
    modalEditSuelo: false,

    napa: "",
    napas: ["Con Influencia", "Sin Influencia", "Sin Dato"],
    profundidadNapa: "",
    estadoPerfilEnSiembra: "",
    estadosPerfil: ["Vacío", "Medio", "Lleno", "Sin Dato"],

    fechaAnalisis: "",
    fosforo: "",
    nn03_0_20cm: "",
    nn03_20_40cm: "",
    nn03_40_60cm: "",
    materiaOrganica: "",
    aguaUtilSiembra: "",

    modalAgua: {
      napa: "",
      profundidadNapa: "",
      estadoPerfilEnSiembra: ""
    },

    modalSuelo: {
      fechaAnalisis: "",
      fosforo: "",
      nn03_0_20cm: "",
      nn03_20_40cm: "",
      nn03_40_60cm: "",
      materiaOrganica: "",
      aguaUtilSiembra: ""
    },

    icons: {
      edit: pencilOutline
    }
  }),

  computed: {
    ...mapState(["user"]),
    idLote() {
      return this.$route.params.idLote;
    },
    isEditable() {
      return this.campaniaCultivo &&
        (this.campaniaCultivo.abierto === true ||
          this.campaniaCultivo.abierto === false)
        ? this.campaniaCultivo.abierto
        : true;
    },
    formatDate() {
      const date = new Date(this.fechaAnalisis);
      return (
        date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear()
      );
    }
  },

  watch: {
    async campaniaCultivo() {
      this.modalCleaner();
      await this.dataSync();
    },
    async idLote() {
      this.modalCleaner();
      await this.dataSync();
    },
    async idProductor() {
      await this.dataSync();
    }
  },

  async mounted() {
    await this.getMe();
    await this.dataSync();
  },

  methods: {
    ...mapActions(["getMe"]),
    /*     cleaner() {
      this.napa = "";
      this.profundidadNapa = "";
      this.estadoPerfilEnSiembra = "";
      this.fosforo = "";
      this.nn03_0_20cm = "";
      this.nn03_20_40cm = "";
      this.nn03_40_60cm = "";
    }, */

    cancel() {
      this.modalEditAgua = false;
      this.modalEditSuelo = false;
      this.modalCleaner();
    },

    async dataSync() {
      this.agua = (await this.getAgua())[0];
      this.suelo = (await this.getSuelo())[0];
      if (this.agua && this.agua._id) {
        this.napa = this.agua.napa;
        this.profundidadNapa = this.agua.profundidadNapa;
        this.estadoPerfilEnSiembra = this.agua.estadoPerfilEnSiembra;
      } else {
        this.napa = "";
        this.profundidadNapa = "";
        this.estadoPerfilEnSiembra = "";
      }
      if (this.suelo && this.suelo._id) {
        this.fechaAnalisis = this.suelo.fechaAnalisis ? new Date(this.suelo.fechaAnalisis).toISOString() : "";
        this.fosforo = this.suelo.fosforo;
        this.nn03_0_20cm = this.suelo.nn03_0_20cm;
        this.nn03_20_40cm = this.suelo.nn03_20_40cm;
        this.nn03_40_60cm = this.suelo.nn03_40_60cm;
        this.materiaOrganica = this.suelo.materiaOrganica;
        this.aguaUtilSiembra = this.suelo.aguaUtilSiembra;
      } else {
        this.fechaAnalisis = "";
        this.fosforo = "";
        this.nn03_0_20cm = "";
        this.nn03_20_40cm = "";
        this.nn03_40_60cm = "";
        this.materiaOrganica = "";
        this.aguaUtilSiembra = "";
      }
    },

    async getAgua() {
      const { data, error } = await Agua.get({
        query: {
          campaniaCultivo: this.campaniaCultivo._id
        },
        populate: "agua",
        sort: { createdAt: -1 }
      });
      if (error) {
        const alert = await alertController.create({
          header: "Error",
          message: "Error obteniendo datos del agua.",
          buttons: [
            {
              text: "Aceptar"
            }
          ]
        });
        return alert.present();
      }
      return data;
    },

    async getSuelo() {
      const { data, error } = await Suelo.get({
        query: {
          campaniaCultivo: this.campaniaCultivo._id
        },
        populate: "suelo",
        sort: { createdAt: -1 }
      });
      if (error) {
        const alert = await alertController.create({
          header: "Error",
          message: "Error obteniendo datos del suelo.",
          buttons: [
            {
              text: "Aceptar"
            }
          ]
        });
        return alert.present();
      }
      return data;
    },

    fillFieldsModalAgua() {
      this.modalAgua.napa = this.napa;
      this.modalAgua.profundidadNapa = this.profundidadNapa;
      this.modalAgua.estadoPerfilEnSiembra = this.estadoPerfilEnSiembra;
    },

    fillFieldsModalSuelo() {
      this.modalSuelo.fechaAnalisis = this.fechaAnalisis;
      this.modalSuelo.fosforo = this.fosforo;
      this.modalSuelo.nn03_0_20cm = this.nn03_0_20cm;
      this.modalSuelo.nn03_20_40cm = this.nn03_20_40cm;
      this.modalSuelo.nn03_40_60cm = this.nn03_40_60cm;
      this.modalSuelo.materiaOrganica = this.materiaOrganica;
      this.modalSuelo.aguaUtilSiembra = this.aguaUtilSiembra;
    },

    modalCleaner() {
      this.modalAgua.napa = "";
      this.modalAgua.profundidadNapa = "";
      this.modalAgua.estadoPerfilEnSiembra = "";
      this.modalSuelo.fechaAnalisis = "";
      this.modalSuelo.fosforo = "";
      this.modalSuelo.nn03_0_20cm = "";
      this.modalSuelo.nn03_20_40cm = "";
      this.modalSuelo.nn03_40_60cm = "";
      this.modalSuelo.materiaOrganica = "";
      this.modalSuelo.aguaUtilSiembra = "";
    },

    async submitAgua() {
      this.$emit("update:success", false);
      let error = "";
      if (this.modalAgua.profundidadNapa) {
        if (isNaN(this.modalAgua.profundidadNapa)) {
          error +=
            "<li>El campo <b>Profundidad de Napa</b> es numérico." +
            `<br/></li>`;
        } else if (this.modalAgua.profundidadNapa < 0) {
          error +=
            "<li>El campo <b>Profundidad de Napa</b> debe ser un número positivo." +
            `<br/></li>`;
        }
      }

      if (!error) {
        this.$emit("submitAgua", {
          agua: {
            _id: this.agua && this.agua._id ? this.agua._id : undefined,
            napa: this.modalAgua.napa,
            profundidadNapa: this.modalAgua.profundidadNapa,
            estadoPerfilEnSiembra: this.modalAgua.estadoPerfilEnSiembra,
            campaniaCultivo: this.campaniaCultivo
          }
        });
        this.modalEditAgua = false;
        this.modalCleaner();
      } else {
        const alert = await alertController.create({
          header: "Error al editar datos del agua",
          message: error,
          buttons: [
            {
              text: "Aceptar"
            }
          ]
        });
        alert.present();
      }
    },

    async submitSuelo() {
      this.$emit("update:success", false);
      let error = "";
      if (!this.modalSuelo.fechaAnalisis) {
        error +=
          "<li>Complete el campo <b>Fecha de Análisis de Suelo</b>." +
          `<br/></li>`;
      }

      if (this.modalSuelo.fosforo) {
        if (isNaN(this.modalSuelo.fosforo)) {
          error += "<li>El campo <b>Fósforo</b> es numérico." + `<br/></li>`;
        } else if (this.modalSuelo.fosforo < 0) {
          error +=
            "<li>El campo <b>Fósforo</b> debe ser un número positivo." +
            `<br/></li>`;
        }
      }

      if (this.modalSuelo.nn03_0_20cm) {
        if (isNaN(this.modalSuelo.nn03_0_20cm)) {
          error +=
            "<li>El campo <b>N-NO3 (0-20cm)</b> es numérico." + `<br/></li>`;
        } else if (this.modalSuelo.nn03_0_20cm < 0) {
          error +=
            "<li>El campo <b>N-NO3 (0-20cm)</b> debe ser un número positivo." +
            `<br/></li>`;
        }
      }

      if (this.modalSuelo.nn03_20_40cm) {
        if (isNaN(this.modalSuelo.nn03_20_40cm)) {
          error +=
            "<li>El campo <b>N-NO3 (20-40cm)</b> es numérico." + `<br/></li>`;
        } else if (this.modalSuelo.nn03_20_40cm < 0) {
          error +=
            "<li>El campo <b>N-NO3 (20-40cm)</b> debe ser un número positivo." +
            `<br/></li>`;
        }
      }

      if (this.modalSuelo.nn03_40_60cm) {
        if (isNaN(this.modalSuelo.nn03_40_60cm)) {
          error +=
            "<li>El campo <b>N-NO3 (40-60cm)</b> es numérico." + `<br/></li>`;
        } else if (this.modalSuelo.nn03_40_60cm < 0) {
          error +=
            "<li>El campo <b>N-NO3 (40-60cm)</b> debe ser un número positivo." +
            `<br/></li>`;
        }
      }

      if (this.modalSuelo.materiaOrganica) {
        if (isNaN(this.modalSuelo.materiaOrganica)) {
          error +=
            "<li>El campo <b>Materia Orgánica</b> es numérico." + `<br/></li>`;
        } else if (
          this.modalSuelo.materiaOrganica < 0 ||
          this.modalSuelo.materiaOrganica > 100
        ) {
          error +=
            "<li>El campo <b>Materia Orgánica</b> debe ser un valor de porcentaje(%) entre 0 y 100." +
            `<br/></li>`;
        }
      }

      if (this.modalSuelo.aguaUtilSiembra) {
        if (isNaN(this.modalSuelo.aguaUtilSiembra)) {
          error +=
            "<li>El campo <b>Agua Útil a la Siembra</b> es numérico." +
            `<br/></li>`;
        } else if (this.modalSuelo.aguaUtilSiembra < 0) {
          error +=
            "<li>El campo <b>Agua Útil a la Siembra</b> debe ser un número positivo." +
            `<br/></li>`;
        }
      }

      if (!error) {
        this.$emit("submitSuelo", {
          suelo: {
            _id: this.suelo && this.suelo._id ? this.suelo._id : undefined,
            fechaAnalisis: this.modalSuelo.fechaAnalisis,
            fosforo: this.modalSuelo.fosforo,
            nn03_0_20cm: this.modalSuelo.nn03_0_20cm,
            nn03_20_40cm: this.modalSuelo.nn03_20_40cm,
            nn03_40_60cm: this.modalSuelo.nn03_40_60cm,
            materiaOrganica: this.modalSuelo.materiaOrganica,
            aguaUtilSiembra: this.modalSuelo.aguaUtilSiembra,
            campaniaCultivo: this.campaniaCultivo
          }
        });
        this.modalEditSuelo = false;
        this.modalCleaner();
      } else {
        const alert = await alertController.create({
          header: "Error al editar datos del Suelo",
          message: error,
          buttons: [
            {
              text: "Aceptar"
            }
          ]
        });
        alert.present();
      }
    },

    modalEditSueloHandler() {
      this.fillFieldsModalSuelo();
      this.modalEditSuelo = true;
    },

    modalEditAguaHandler() {
      this.fillFieldsModalAgua();
      this.modalEditAgua = true;
    }
  }
};
</script>

<style scoped>
.error {
  color: red;
  font-size: smaller;
  font-style: italic;
  font-weight: 500;
  margin-top: 4px;
}

.negrita {
  color: black;
  font-size: large;
  margin-left: 4px;
  font-weight: bold;
}

.label {
  font-size: medium;
}

.margen5px {
  margin-left: 5px;
}

ion-item {
  --pading-start: 8px;
}

ion-content {
  --background: transparent;
}
</style>
